
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Collapse } from 'bootstrap'
import short from 'short-uuid'

@Component
export default class HelpToggle extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop({ default: () => short.generate() })
  id!: string;

  @Prop({ default: false, type: Boolean })
  hideIcon!: string;

  ref: any;
  shown = false;

  mounted() {
    this.ref = new Collapse(this.$refs.collapse as Element, { toggle: false })
  }

  toggle() {
    this.shown = !this.shown
    this.ref.toggle()
    this.$emit('toggle', this.shown);
  }
}
